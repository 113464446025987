<!--
 * @Description: 新装回访列表
 * @Author: ChenXueLin
 * @Date: 2021-10-20 13:55:12
 * @LastEditTime: 2022-07-15 16:42:33
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="corpId">
              <e6-vr-select
                v-model="searchForm.corpId"
                :data="corpList"
                placeholder="客户名称"
                title="客户名称"
                clearable
                virtual
                remote
                :is-title="true"
                @filterChange="handleLoadCorpFilter"
                :props="{
                  id: 'corpId',
                  label: 'corpName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="employeeId">
              <e6-vr-select
                v-model="searchForm.employeeId"
                :data="employeeList"
                placeholder="业务员"
                title="业务员"
                :props="{
                  id: 'employeeId',
                  label: 'userName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="attrIds">
              <e6-vr-select
                v-model="searchForm.attrIds"
                :data="corpAttrEnum"
                placeholder="客户属性"
                title="客户属性"
                :props="{
                  id: 'id',
                  label: 'attrValueDesc'
                }"
                multiple
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpClass">
              <e6-vr-select
                v-model="searchForm.corpClass"
                :data="corpClassEnum"
                placeholder="客户分级"
                title="客户分级"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="companyId">
              <e6-vr-select
                v-model="searchForm.companyId"
                :data="companyEnum"
                placeholder="所属主体"
                title="所属主体"
                :props="{
                  id: 'companyId',
                  label: 'companyFullName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="issuccess">
              <e6-vr-select
                v-model="searchForm.issuccess"
                :data="revisitStatusList"
                placeholder="回访状态"
                title="回访状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="currentMonthTimeStamp">
              <el-date-picker
                style="width:200px"
                v-model="searchForm.currentMonthTimeStamp"
                type="month"
                placeholder="选择月"
                value-format="timestamp"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          ref="tableList"
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'documentNumber'">
                <el-button type="text" @click="toTaskDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->
      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  getSatisfactionList,
  getListIdAndNames,
  findDownList,
  getCompanydownList,
  getCorpAttrDownList
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "customerSatisfactionReturnVisit",
  mixins: [listPage, listPageReszie, base],
  components: {},
  data() {
    return {
      total: 0,
      documentTypeList: [], //单据类型下拉框
      returnVisitTypeList: [], //回访状态下拉框
      searchForm: {
        corpId: "", //客户名称
        attrIds: [], //客户属性
        employeeId: "", //业务员id
        issuccess: "", //回访状态
        companyId: "", //所属主体
        corpClass: "", //客户分级
        currentMonthTimeStamp: new Date().getTime(),
        pageIndex: 1,
        pageSize: 20
      },
      corpList: [], //客户名称
      employeeList: [], //业务员列表
      corpAttrEnum: [], //客户属性列表
      corpClassEnum: [], //客户分级列表
      companyEnum: [], //所属主体列表
      revisitStatusList: [], //回访状态下拉框
      columnData: [
        {
          fieldName: "revisitId",
          display: true,
          fieldLabel: "回访ID",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "companySimName",
          display: true,
          fieldLabel: "主体简称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpClassStr",
          display: true,
          fieldLabel: "客户分级",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "areaName",
          display: true,
          fieldLabel: "大区",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "officeName",
          display: true,
          fieldLabel: "办事处",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "attrName",
          display: true,
          fieldLabel: "客户属性",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitTime",
          display: true,
          fieldLabel: "回访日期",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitManName",
          display: true,
          fieldLabel: "回访人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitedManName",
          display: true,
          fieldLabel: "被回访人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "issuccessStr",
          display: true,
          fieldLabel: "回访状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      refTable: "tableList",
      queryListAPI: getSatisfactionList
    };
  },
  computed: {},
  watch: {},
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 初始化数据
    async initData() {
      try {
        let promiseList = [
          findDownList(["RevisitStatus", "returnVisitType", "corpClass"]),
          getListIdAndNames({
            id: 4
          }),
          getCorpAttrDownList({}),
          getCompanydownList({ isManageEmp: false, companyId: "" })
        ];
        let [res, employeeRes, attrRes, companyEnumRes] = await Promise.all(
          promiseList
        );
        //回访状态下拉框
        this.revisitStatusList = this.getFreezeResponse(res, {
          path: "data.RevisitStatus"
        });
        //客户分级
        this.corpClassEnum = this.getFreezeResponse(res, {
          path: "data.corpClass"
        });
        //业务员
        this.employeeList = this.getFreezeResponse(employeeRes, {
          path: "data"
        });
        //客户属性
        this.corpAttrEnum = this.getFreezeResponse(attrRes, {
          path: "data"
        });
        //所属主体
        this.companyEnum = this.getFreezeResponse(companyEnumRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    handleSearch() {
      if (!this.searchForm.currentMonthTimeStamp) {
        this.$message.warning("请选择当前月份");
        return;
      }
      this.queryList();
    },
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //去任务单详情
    toTaskDetail(row) {
      if (row.documentNumber.indexOf("RWD") > -1) {
        this.routerPush({
          name: "taskListManage/detail",
          params: {
            refresh: true,
            taskId: row.documentId
          }
        });
      }
      if (row.documentNumber.indexOf("FWD") > -1) {
        this.routerPush({
          name: "serviceListManage/serviceDetail",
          params: {
            refresh: true,
            serviceId: row.documentId
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>

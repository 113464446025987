var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-view-wrapper pd-10"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"sectionWrapper",staticClass:"content-view section-wrapper",attrs:{"element-loading-background":_vm.loadingBackground}},[_c('table-title',{on:{"resize":_vm.handleTableTitleResize},scopedSlots:_vm._u([{key:"searchForm",fn:function(){return [_c('el-form',{ref:"searchForm",staticClass:"search-list",attrs:{"model":_vm.searchForm}},[_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"corpId"}},[_c('e6-vr-select',{attrs:{"data":_vm.corpList,"placeholder":"客户名称","title":"客户名称","clearable":"","virtual":"","remote":"","is-title":true,"props":{
                id: 'corpId',
                label: 'corpName'
              }},on:{"filterChange":_vm.handleLoadCorpFilter},model:{value:(_vm.searchForm.corpId),callback:function ($$v) {_vm.$set(_vm.searchForm, "corpId", $$v)},expression:"searchForm.corpId"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"employeeId"}},[_c('e6-vr-select',{attrs:{"data":_vm.employeeList,"placeholder":"业务员","title":"业务员","props":{
                id: 'employeeId',
                label: 'userName'
              },"clearable":""},model:{value:(_vm.searchForm.employeeId),callback:function ($$v) {_vm.$set(_vm.searchForm, "employeeId", $$v)},expression:"searchForm.employeeId"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"attrIds"}},[_c('e6-vr-select',{attrs:{"data":_vm.corpAttrEnum,"placeholder":"客户属性","title":"客户属性","props":{
                id: 'id',
                label: 'attrValueDesc'
              },"multiple":"","clearable":""},model:{value:(_vm.searchForm.attrIds),callback:function ($$v) {_vm.$set(_vm.searchForm, "attrIds", $$v)},expression:"searchForm.attrIds"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"corpClass"}},[_c('e6-vr-select',{attrs:{"data":_vm.corpClassEnum,"placeholder":"客户分级","title":"客户分级","props":{
                id: 'codeValue',
                label: 'codeName'
              },"clearable":""},model:{value:(_vm.searchForm.corpClass),callback:function ($$v) {_vm.$set(_vm.searchForm, "corpClass", $$v)},expression:"searchForm.corpClass"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"companyId"}},[_c('e6-vr-select',{attrs:{"data":_vm.companyEnum,"placeholder":"所属主体","title":"所属主体","props":{
                id: 'companyId',
                label: 'companyFullName'
              },"clearable":""},model:{value:(_vm.searchForm.companyId),callback:function ($$v) {_vm.$set(_vm.searchForm, "companyId", $$v)},expression:"searchForm.companyId"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"issuccess"}},[_c('e6-vr-select',{attrs:{"data":_vm.revisitStatusList,"placeholder":"回访状态","title":"回访状态","clearable":"","props":{
                id: 'codeValue',
                label: 'codeName'
              }},model:{value:(_vm.searchForm.issuccess),callback:function ($$v) {_vm.$set(_vm.searchForm, "issuccess", $$v)},expression:"searchForm.issuccess"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--2",attrs:{"prop":"currentMonthTimeStamp"}},[_c('el-date-picker',{staticStyle:{"width":"200px"},attrs:{"type":"month","placeholder":"选择月","value-format":"timestamp"},model:{value:(_vm.searchForm.currentMonthTimeStamp),callback:function ($$v) {_vm.$set(_vm.searchForm, "currentMonthTimeStamp", $$v)},expression:"searchForm.currentMonthTimeStamp"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--buttons"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("查询")]),_vm._v(" "),_c('el-button',{staticClass:"reset",on:{"click":_vm.handleReset}},[_vm._v("重置")])],1)],1)]},proxy:true}])}),_vm._v(" "),_c('section',{staticClass:"table-wrapper"},[_c('el-table',{ref:"tableList",staticClass:"elTable",attrs:{"border":"","height":_vm.resizeViewHeight + 'px',"data":_vm.tableData,"highlight-current-row":""}},[_c('el-table-column',{attrs:{"type":"selection","width":"40"}}),_vm._v(" "),_vm._l((_vm.columnData),function(column,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":column.fieldName,"label":column.fieldLabel,"min-width":column.width,"fixed":column.fixed,"align":column.align,"header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [(column.fieldName === 'documentNumber')?_c('span',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.toTaskDetail(row)}}},[_vm._v("\n                "+_vm._s(row[column.fieldName])+"\n              ")])],1):_c('span',[_vm._v(_vm._s(row[column.fieldName]))])]}}],null,true)})})],2)],1),_vm._v(" "),_c('section',{staticClass:"pagination-wrapper fixed-section"},[_c('el-pagination',{attrs:{"page-size":_vm.searchForm.pageSize,"current-page":_vm.searchForm.pageIndex,"page-sizes":_vm.pageSizes,"layout":_vm.layout,"total":_vm.total,"background":""},on:{"update:pageSize":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:currentPage":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"update:current-page":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }